<template>
  <svg-icon :data="sData" v-bind="$attrs" class="pkg-feather" outlined />
</template>
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";

@Component
export default class IconSettings extends Vue {
  sData: string = require("@icon/feather/settings.svg");
}
</script>
